/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

const items = [
  {
    altText: "",
    caption: "Big City Life, United States",
    src: require("assets/img/denys.jpg"),
  },
  {
    altText: "",
    caption: "Somewhere Beyond, United States",
    src: require("assets/img/fabien-bazanegue.jpg"),
  },
  {
    altText: "",
    caption: "Stocks, United States",
    src: require("assets/img/mark-finn.jpg"),
  },
];

class Profile extends React.Component {
  state = {
    tabs: 1,
    activeIndex: 0,
  };
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("profile-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("profile-page");
  }
  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index,
    });
  };
  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  next = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex = (newIndex) => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  };
  render() {
    return (
      <>
        <ColorNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="page-header">
            <img
              alt="..."
              className="dots"
              src={require("assets/img/dots.png")}
            />
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png")}
            />
            <Container className="align-items-center">
              <Row>
                <Col lg="6" md="6">
                  <h1 className="profile-title text-left">Aaron Su</h1>
                  <h5 className="text-on-back">01</h5>
                  <p className="profile-description">
                    I am a double Bachelor of Engineering and Commerce graduated
                    with Honours Class 1 in Mechatronics and Distinction in
                    Financial Economics from UNSW. <br />
                    <br />I am currently doing research and tutoring in AI &
                    Robotics as well as constantly developing skills in cloud
                    technology. Seeking a graduate position in cloud, artificial
                    intelligence or robotics applications/products development.
                  </p>
                  <div className="btn-wrapper profile pt-3">
                    <Button
                      className="btn-icon btn-round"
                      color="linkedin"
                      href="https://www.linkedin.com/in/aaronsu11"
                      id="tooltip337991226"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin" />
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip337991226">
                      Connect with Linkedin
                    </UncontrolledTooltip>
                    <Button
                      className="btn-icon btn-round"
                      color="github"
                      href="https://github.com/aaronsu11"
                      id="tooltip304767046"
                      target="_blank"
                    >
                      <i className="fab fa-github" />
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip304767046">
                      Connect with Github
                    </UncontrolledTooltip>
                  </div>
                </Col>
                <Col className="ml-auto mr-auto" lg="4" md="6">
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid rounded-circle"
                        src={require("assets/img/aaron-dp-w.jpg")}
                      />
                      <h4 className="title">My Profile</h4>
                    </CardHeader>
                    <CardBody>
                      <Nav
                        className="nav-tabs-primary justify-content-center"
                        tabs
                      >
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.tabs === 1,
                            })}
                            onClick={(e) => this.toggleTabs(e, "tabs", 1)}
                          >
                            Specialties
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.tabs === 2,
                            })}
                            // onClick={(e) => this.toggleTabs(e, "tabs", 2)}
                            href="https://ainz11-assets.s3-ap-southeast-2.amazonaws.com/Aaron_Su_Resume.pdf"
                            target="_blank"
                          >
                            Resume
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        className="tab-subcategories"
                        activeTab={"tab" + this.state.tabs}
                      >
                        <TabPane tabId="tab1">
                          <Table className="tablesorter" responsive>
                            {/* <thead className="text-primary">
                              <tr>
                                <th className="header">Specialties</th>
                              </tr>
                            </thead> */}
                            <tbody>
                              <tr>
                                <td>Full-stack web developer</td>
                              </tr>
                              <tr>
                                <td>AI & Robot Engineer</td>
                              </tr>
                              <tr>
                                <td>Solution Architect</td>
                              </tr>
                            </tbody>
                          </Table>
                        </TabPane>
                        <TabPane tabId="tab2">
                          <Row>
                            {/* <Label sm="3">Amount</Label>
                            <Col sm="9">
                              <FormGroup>
                                <Input placeholder="1.587" type="text" />
                              </FormGroup>
                            </Col> */}
                          </Row>
                          <Button
                            className="btn-simple btn-icon btn-round float-right"
                            color="primary"
                          >
                            <i className="tim-icons icon-send" />
                          </Button>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          {/* <div className="section">
            <Container>
              <Row className="justify-content-between">
                <Col md="6">
                  <Row className="justify-content-between align-items-center">
                    <Carousel
                      activeIndex={this.state.activeIndex}
                      next={this.next}
                      previous={this.previous}
                    >
                      <CarouselIndicators
                        items={items}
                        activeIndex={this.state.activeIndex}
                        onClickHandler={this.goToIndex}
                      />
                      {items.map((prop, key) => {
                        return (
                          <CarouselItem
                            onExiting={this.onExiting}
                            onExited={this.onExited}
                            key={prop.src}
                          >
                            <img src={prop.src} alt="..." className="d-block" />
                            <div className="carousel-caption d-none d-md-block">
                              <h5>{prop.caption}</h5>
                            </div>
                          </CarouselItem>
                        );
                      })}
                      <a
                        className="carousel-control-prev"
                        data-slide="prev"
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          this.previous();
                        }}
                        role="button"
                      >
                        <i className="tim-icons icon-minimal-left" />
                      </a>
                      <a
                        className="carousel-control-next"
                        data-slide="next"
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          this.next();
                        }}
                        role="button"
                      >
                        <i className="tim-icons icon-minimal-right" />
                      </a>
                    </Carousel>
                  </Row>
                </Col>
                <Col md="5">
                  <h1 className="profile-title text-left">Fun Facts</h1>
                  <h5 className="text-on-back">02</h5>
                  <p className="profile-description text-left">
                    An artist of considerable range, Ryan — the name taken by
                    Melbourne-raised, Brooklyn-based Nick Murphy — writes,
                    performs and records all of his own music, giving it a warm,
                    intimate feel with a solid groove structure. An artist of
                    considerable range.
                  </p>
                  <div className="btn-wrapper pt-3">
                    <Button
                      className="btn-simple"
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="tim-icons icon-book-bookmark" /> Bookmark
                    </Button>
                    <Button
                      className="btn-simple ml-1"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="tim-icons icon-bulb-63" /> Check it!
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div> */}
          <section className="section">
            <Container>
              <Row>
                <Col md="6">
                  <Card className="card-plain">
                    <CardHeader>
                      <h1 className="profile-title text-left">Contact</h1>
                      <h5 className="text-on-back">02</h5>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label>Your Name</label>
                              <Input placeholder="Elon Musk" type="text" />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Email address</label>
                              <Input
                                placeholder="elon-musk@email.com"
                                type="email"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label>Phone</label>
                              <Input placeholder="+61 123456789" type="text" />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Company</label>
                              <Input placeholder="Neuralink" type="text" />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label>Message</label>
                              <Input placeholder="Work with us!" type="text" />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Button
                          className="btn-round float-right"
                          color="primary"
                          id="tooltip191750994"
                          type="button"
                        >
                          Send text
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          placement="right"
                          target="tooltip191750994"
                        >
                          Can't wait for your message
                        </UncontrolledTooltip>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="ml-auto" lg="4" md="6">
                  <div className="info info-horizontal">
                    <div className="icon icon-primary">
                      <i className="tim-icons icon-square-pin" />
                    </div>
                    <div className="description">
                      <h4 className="info-title">Find us at the office</h4>
                      <p>
                        Haymarket, NSW 2000, <br />
                        Australia
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-primary">
                      <i className="tim-icons icon-mobile" />
                    </div>
                    <div className="description">
                      <h4 className="info-title">Email Me</h4>
                      <p>
                        Aaron Su <br />
                        aaronsu11@outlook.com <br />
                        Mon - Fri, 10:00-22:00
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <DemoFooter />
        </div>
      </>
    );
  }
}

export default Profile;
