/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import { Button, Card, CardBody, Table } from "reactstrap";

function ResultsTable(props) {
  return (
    <>
      <div id="tables">
        <Card className="card-plain">
          <CardBody>
            <Table className="tablesorter" responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(props.results).map(([key, value]) => {
                  return (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>
                        {Array.isArray(value)
                          ? value.map((item, i) => (
                              <div key={key + "" + i}>
                                {item.toString()}
                                <br />
                              </div>
                            ))
                          : value.toString()}
                      </td>
                      <td className="text-center">
                        <Button
                          className="btn-icon btn-simple ml-1"
                          color="success"
                          size="sm"
                        >
                          <i className="tim-icons icon-settings" />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

ResultsTable.propTypes = {
  results: PropTypes.object,
};

export default ResultsTable;
