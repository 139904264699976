//Get dummy data
// import { projectList } from "variables/general";

//Get Environment Variables
const env = process.env.NODE_ENV;
const generalApiUrl = process.env.REACT_APP_GENERAL_API_URL;
const oldApiUrl = process.env.REACT_APP_OLD_API_URL;
const cvApiUrl = process.env.REACT_APP_CV_API_URL;

export const serverIsReady = async () => {
  // Warm up the service
  const cvURL = new URL("image/warm-up", cvApiUrl);
  try {
    let response = await fetch(cvURL, {
      method: "get",
    });
    if (!response.ok) throw new Error(response.status);
    const data = await response.json();
    // console.log(data.message);
    return data.ready;
  } catch (err) {
    // No need to warm-up in local development environment
    if (env === "development") {
      // console.log("Using Dev Server");
      if (err instanceof TypeError) {
        // handle server warm-up period (TypeError exceptions)
        console.log("Waiting for server to get ready");
        return false;
      }
    }
    console.log(err);
    return null;
  }
};

export const fetchProjects = async () => {
  const projectsURL = new URL("projects", oldApiUrl);
  try {
    let response = await fetch(projectsURL, {
      method: "get",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     userid: token,
      //     type: "vineyardls",
      //   }),
    });
    if (!response.ok) throw new Error(response.status);
    let obj = await response.json();
    return JSON.parse(obj);
  } catch (err) {
    alert(err);
    return null;
  }
};

/**
 * Get pre-signed URL to get or put files in S3
 * @function
 * @param {object} params - The parameters of the query string
 */
export const getPresignedURL = async (params) => {
  const cvURL = new URL("image", generalApiUrl);
  // const cvURL = new URL("projects/computer-vision", apiURL);
  cvURL.search = new URLSearchParams(params).toString();
  console.log(cvURL.toString());

  try {
    let response = await fetch(cvURL);
    // console.log(response);
    if (!response.ok) throw new Error(response.status);
    let url = await response.json();

    if (params.Task === "put") {
      // Get pre-signed URL for PUT
      return {
        uploadUrl: url,
        fileUrl: url.split(/[?#]/)[0], // remove query string and hash
      };
    } else {
      // Get pre-signed URL for GET
      return {
        accessUrl: url,
      };
    }

    // return JSON.parse(url);
  } catch (err) {
    alert(err);
    return null;
  }
};

export const recognizeObject = async (imgName) => {
  const cvURL = new URL("projects/computer-vision", oldApiUrl);
  try {
    let response = await fetch(cvURL, {
      method: "post",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      body: JSON.stringify({
        img_name: imgName,
      }),
    });
    if (!response.ok) throw new Error(response.status);
    let obj = await response.json();
    return obj.Labels;
    // return JSON.parse(url);
  } catch (err) {
    alert(err);
    return null;
  }
};

export const detectStomata = async (imgName) => {
  const cvURL = new URL("image/detect", cvApiUrl);
  try {
    console.log(imgName);
    // throw new Error();
    let response = await fetch(cvURL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: imgName,
      }),
    });
    console.log(response);
    if (!response.ok) throw new Error(response.status);
    const results = await response.json();
    return results;
  } catch (err) {
    alert(err);
    return null;
  }
};
