let projectList = [
  {
    id: 0,
    featured: true,
    title: "AI and Robotics",
    subtitle: "We are happy to work at such a great project.",
    description: `“Artificial intelligence will reach human levels by around 2029. Follow that out further to, say, 2045, and we will have multiplied the intelligence – the human biological machine intelligence of our civilization – a billion-fold.” -- Ray Kurzweil, American inventor and futurist.`,
    memberIDs: [0, 1, 2, 3],
    logoURL: "slack.png",
    coverURL: "bumblebee.jpg",
    siteURL: "/project-x",
  },
  {
    id: 1,
    featured: true,
    title: "Computer Vision",
    subtitle: "We are happy to work at Computer Vision project.",
    description: `Computer vision is an interdisciplinary scientific field that deals with how computers can gain high-level understanding from digital images or videos. From the perspective of engineering, it seeks to understand and automate tasks that the human visual system can do.`,
    memberIDs: [0, 1, 2, 3],
    logoURL: "dribbble.png",
    coverURL: "vision.jpg",
    siteURL: "/computer-vision",
  },
  {
    id: 2,
    featured: true,
    title: "Interactive Video Streaming",
    subtitle: "We are happy to work at Project 2.",
    description: `Within the first few months of 2020, we’ve already seen a rapid increase in the role video streaming plays in everyday life. The COVID-19 outbreak has demonstrated how essential virtual learning, video conferencing, telemedicine, and similar technologies are to business continuity.`,
    memberIDs: [0, 1, 2, 3],
    logoURL: "spotify.jpeg",
    coverURL: "streaming.jpg",
    siteURL: "/coming",
  },
  {
    id: 3,
    featured: true,
    title: "Cloud Gaming",
    subtitle: "We are happy to work at Project 3.",
    description: `Cloud gaming, sometimes called gaming on demand or gaming-as-a-service, is a type of online gaming that runs games on remote servers and streams them directly to a user’s device, or more colloquially, playing a game remotely from a cloud.`,
    memberIDs: [0, 1, 2, 3],
    logoURL: "Tim.png",
    coverURL: "gaming.jpg",
    siteURL: "/coming",
  },
  {
    id: 4,
    featured: true,
    title: "XR",
    subtitle: "We are happy to work at such a great project.",
    description: `X Reality (XR) encompasses a wide spectrum of hardware and software, including sensory interfaces, applications, and infrastructures, that enable content creation for virtual reality (VR), mixed reality (MR), cinematic reality (CR). With these tools, users generate new forms of reality by bringing digital objects into the physical world and bringing physical world objects into the digital world.`,
    memberIDs: [0, 1, 2, 3],
    logoURL: "dropbox.png",
    coverURL: "aincrad.jpg",
    siteURL: "/coming",
  },
  {
    id: 5,
    featured: false,
    title: "Something Fun",
    subtitle: "We are happy to work at such a great project.",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing
    elit, sed do eiusmod tempor incididunt ut labore et
    dolore magna aliqua. Porta nibh venenatis cras sed
    felis eget velit aliquet sagittis.`,
    memberIDs: [0, 1, 2, 3],
    logoURL: "dropbox.png",
    coverURL: "serge-kutuzov.jpg",
    siteURL: "/coming",
  },
];

module.exports = {
  projectList,
};
