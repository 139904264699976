/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import Dropzone from "react-dropzone-uploader";
import { Button, Progress } from "reactstrap";
import Lottie from "react-lottie";

// import fetch functions
import { getPresignedURL, detectStomata } from "actions/fetchActions";

// import assets
// import defaultImage from "assets/img/image_placeholder.jpg";
import * as spinner from "assets/lottie/spinner.json";

const spinnerOption = {
  loop: true,
  autoplay: true,
  animationData: spinner.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function DropzoneSingleUpload(props) {
  const [loaded, setLoaded] = useState(false);
  const [resultImageURL, setResultImageURL] = useState("");

  const Preview = ({ fileWithMeta: { meta, remove } }) => {
    // console.log(meta, remove);
    const { name, percent, status, previewUrl } = meta;
    return (
      <>
        <div className="dzu-img-container">
          {resultImageURL ? (
            <>
              <img
                alt="..."
                className="img rounded img-raised"
                src={resultImageURL ? resultImageURL : previewUrl}
              />
              <div className="dzu-img-overlay">
                <Button
                  className="btn-round ml-1"
                  color="primary"
                  type="button"
                  onClick={() => {
                    remove();
                    setLoaded(false);
                    props.setStatus("idle");
                    props.setResults({});
                  }}
                >
                  <i className="tim-icons icon-simple-remove" /> Reset
                </Button>
              </div>
            </>
          ) : (
            <>
              <img
                alt="..."
                className="img rounded img-raised"
                src={previewUrl}
              />
              <div className="dzu-img-overlay">
                <h3 className="text-warning">Processing...</h3>
                <Lottie options={spinnerOption} height={100} width={100} />
              </div>
            </>
          )}
        </div>
        <br />
        {/* Upload Progress */}
        <div className="progress-container progress-primary text-left">
          <span>{name}</span>
          <Progress max="100" value={Math.round(percent)}>
            <span className="progress-value">
              {Math.round(percent)}% Uploaded
            </span>
          </Progress>
          <span className="progress-badge">{status}</span>
        </div>
        {/* Processing Progress */}
        {/* <div className="progress-container progress-primary text-left">
          <Progress max="100" value={Math.round(percent)}>
            <span className="progress-value">{Math.round(percent)}%</span>
          </Progress>
          <span className="progress-badge">{status}</span>
        </div> */}
      </>
    );
  };

  const getUploadParams = async ({ file, meta: { name, type } }) => {
    setLoaded(true);
    // throw new Error();
    const params = {
      Task: "put",
      Key: name,
      ContentType: type,
    };
    const { uploadUrl, fileUrl } = await getPresignedURL(params);
    return { method: "put", body: file, meta: { fileUrl }, url: uploadUrl };
  };

  const handleChangeStatus = async ({ meta, remove }, status) => {
    if (status === "done") {
      // done uploading
      props.setStatus("uploaded");
      // This should return the processed image pre-signed URL
      const response = await detectStomata(meta.name);
      const { presigned_url, ...results } = response;
      props.setStatus("processed");
      props.setResults(results);
      setResultImageURL(presigned_url);

      // } else if (status === "headers_received") {
      //   console.log(status, meta);
    } else if (status === "aborted") {
      console.log(status);
      // remove();
    } else {
      // props.setStatus(status);
      console.log(status);
    }
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      maxFiles={1}
      multiple={false}
      canCancel={false}
      PreviewComponent={Preview}
      inputContent="Dropzone"
      styles={{
        dropzone: {
          borderColor: loaded ? "#ffffff00" : "#d9d9d9c2",
          backgroundColor: loaded ? "#00000000" : "#0000001e",
        },
        dropzoneActive: {
          borderColor: "#e14eca",
          backgroundColor: "#deebff83",
        },
        inputLabel: {
          color: props.serverReady ? "#e14eca" : "rgba(255, 255, 255, 0.6)",
        },
      }}
      disabled={() => !props.serverReady}
    />
  );
}

DropzoneSingleUpload.defaultProps = {
  removeBtnClasses: "btn-round",
  removeBtnColor: "danger",
};

DropzoneSingleUpload.propTypes = {
  removeBtnClasses: PropTypes.string,
  removeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
  serverReady: PropTypes.bool,
  setStatus: PropTypes.func,
  setResults: PropTypes.func,
};

export default DropzoneSingleUpload;
