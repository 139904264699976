/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";

// import fetch functions
import { fetchProjects } from "actions/fetchActions";

function Projects() {
  const [activeTab, setActiveTab] = useState("1");
  const [projectList, setProjectList] = useState();
  const history = useHistory();
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    const displayProjects = async () => {
      let projectList = await fetchProjects();
      setProjectList(projectList);
    };
    displayProjects();
  }, []);

  return (
    <>
      <ColorNavbar />
      <div className="cd-section" id="projects">
        {/* ********* PROJECTS 2 ********* */}
        <div className="projects-2 project-raised">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center mb-5" lg="8">
                <h2 className="title">Our Awesome Projects</h2>
                <div className="section-space" />
              </Col>
            </Row>
            <Nav
              className="nav-pills-info nav-pills-icons nav-pills-lg"
              pills
              role="tablist"
            >
              <NavItem className="m-auto">
                <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  <i className="tim-icons icon-spaceship" />
                  Project 1
                </NavLink>
              </NavItem>
              <NavItem className="m-auto">
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  <i className="tim-icons icon-bag-16" />
                  Project 2
                </NavLink>
              </NavItem>
              <NavItem className="m-auto">
                <NavLink
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  <i className="tim-icons icon-light-3" />
                  Project 3
                </NavLink>
              </NavItem>
              <NavItem className="m-auto">
                <NavLink
                  className={activeTab === "4" ? "active" : ""}
                  onClick={() => {
                    toggle("4");
                  }}
                >
                  <i className="tim-icons icon-molecule-40" />
                  Project 4
                </NavLink>
              </NavItem>
              <NavItem className="mx-auto">
                <NavLink
                  className={activeTab === "5" ? "active" : ""}
                  onClick={() => {
                    toggle("5");
                  }}
                >
                  <i className="tim-icons icon-planet" />
                  Project 5
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="tab-space" activeTab={"project" + activeTab}>
              <TabPane tabId="project1">
                <Row className="mt-5">
                  <Col md="4">
                    <Card>
                      <div className="card-image h-100">
                        <img
                          alt="..."
                          className="img rounded"
                          src="https://s3.amazonaws.com/creativetim_bucket/products/83/thumb/opt_mk_react_thumbnail.jpg?1525851474"
                        />
                      </div>
                      <CardBody className="text-left">
                        <CardFooter className="mt-0">
                          <div className="stats stats-right">
                            <div className="stars text-warning">
                              <i className="tim-icons icon-shape-star" />
                              <i className="tim-icons icon-shape-star ml-1" />
                              <i className="tim-icons icon-shape-star ml-1" />
                              <i className="tim-icons icon-shape-star ml-1" />
                              <i className="tim-icons icon-shape-star ml-1" />
                            </div>
                          </div>
                          <div className="author">
                            <img
                              alt="..."
                              className="avatar img-raised"
                              src={require("assets/img/Tim.png")}
                            />
                            <span className="ml-1">Material Kit React</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card>
                      <div className="card-image h-100">
                        <img
                          alt="..."
                          className="img rounded"
                          src="https://s3.amazonaws.com/creativetim_bucket/products/136/thumb/opt_bd_react.jpg?1543482024"
                        />
                      </div>
                      <CardBody className="text-left">
                        <CardFooter className="mt-0">
                          <div className="stats stats-right">
                            <i className="tim-icons icon-heart-2 text-danger" />{" "}
                            342 ·{" "}
                            <i className="tim-icons icon-single-copy-04 text-info" />{" "}
                            43
                          </div>
                          <div className="author">
                            <img
                              alt="..."
                              className="avatar img-raised"
                              src={require("assets/img/Tim.png")}
                            />
                            <span className="ml-1">Black Dashboard React</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card>
                      <div className="card-image h-100">
                        <img
                          alt="..."
                          className="img rounded"
                          src="https://s3.amazonaws.com/creativetim_bucket/products/155/thumb/opt_adp_react_thumbnail.jpg?1554905507"
                        />
                      </div>
                      <CardBody className="text-left">
                        <CardFooter className="mt-0">
                          <div className="stats stats-right">
                            <i className="tim-icons icon-heart-2 text-danger" />{" "}
                            127
                          </div>
                          <div className="author">
                            <img
                              alt="..."
                              className="avatar img-raised"
                              src={require("assets/img/Tim.png")}
                            />
                            <span className="ml-1">
                              Argon Dashboard Pro React
                            </span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="project2">
                <div className="space-100" />
                <Col className="ml-auto mr-auto text-center mt-4" md="8">
                  <p className="description mb-5">
                    Add your information here for Project2.
                  </p>
                </Col>
                <div className="space-100" />
              </TabPane>
              <TabPane tabId="project3">
                <div className="space-100" />
                <Col className="ml-auto mr-auto text-center mt-4" md="8">
                  <p className="description mb-5">
                    Add your information here for Project3.
                  </p>
                </Col>
                <div className="space-100" />
              </TabPane>
              <TabPane tabId="project4">
                <div className="space-100" />
                <Col className="ml-auto mr-auto text-center mt-4" md="8">
                  <p className="description mb-5">
                    Add your information here for Project4.
                  </p>
                </Col>
                <div className="space-100" />
              </TabPane>
              <TabPane tabId="project5">
                <div className="space-100" />
                <Col className="ml-auto mr-auto text-center mt-4" md="8">
                  <p className="description mb-5">
                    Add your information here for Project5.
                  </p>
                </Col>
                <div className="space-100" />
              </TabPane>
            </TabContent>
            <Row>
              <Col className="ml-auto mr-auto text-center mt-4" md="8">
                <h3 className="title">Interested in our projects?</h3>
                <h4 className="description mb-5">
                  This is the paragraph where you can write more details about
                  your projects. Keep you user engaged by providing meaningful
                  information.
                </h4>
                <Button color="primary" size="lg">
                  Contact us
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END PROJECTS 2 ********* */}
        {/* ********* PROJECTS 1 ********* */}
        <div className="projects-1">
          <div className="projects-1">
            <Container>
              <Row>
                <Col className="mr-auto ml-auto text-center mb-5" lg="8">
                  <h2 className="title">Full Projects Lists</h2>
                </Col>
              </Row>
            </Container>
            <Navbar className="bg-transparent mb-5" expand="lg">
              <Container>
                <div className="navbar-translate">
                  <p>52 projects found</p>
                </div>
                <Nav className="ml-auto" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle
                      aria-expanded={false}
                      caret
                      color="default"
                      data-toggle="dropdown"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      id="navbarOrder"
                      nav
                    >
                      <p>A-to-Z</p>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Z-to-A
                      </DropdownItem>
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Newest
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle
                      aria-expanded={false}
                      caret
                      color="default"
                      data-toggle="dropdown"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      id="navbarLatest"
                      nav
                    >
                      <p>Latest</p>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Newest
                      </DropdownItem>
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        High interest
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Container>
            </Navbar>
            <Container>
              <Row>
                {projectList ? (
                  projectList.map((project, index) => {
                    return (
                      <Col md="4" key={index}>
                        <Card>
                          <CardHeader className="mt-2">
                            <div className="float-left">
                              <i className="tim-icons icon-tag d-inline text-info" />
                              <p className="d-inline ml-1">{project.id}</p>
                            </div>
                            <div className="float-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  aria-expanded={false}
                                  caret
                                  className="btn-icon"
                                  color="link"
                                  data-toggle="dropdown"
                                  type="button"
                                >
                                  <i className="tim-icons icon-settings-gear-63" />
                                </DropdownToggle>
                                <DropdownMenu right x-placement="bottom-end">
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={() =>
                                      history.push(project.siteURL)
                                    }
                                  >
                                    Learn more
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Like
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </CardHeader>
                          <CardBody className="text-center p-4">
                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                className="img-fluid rounded-circle shadow"
                                src={require(`assets/img/${project.logoURL}`)}
                                style={{ width: "100px" }}
                              />
                            </a>
                            <CardTitle className="mb-0" tag="h4">
                              {project.title}
                            </CardTitle>
                            <p className="card-description">
                              {project.subtitle}
                            </p>
                            <CardTitle className="mt-4" tag="h5">
                              Members
                            </CardTitle>
                            <div className="avatar-group">
                              <a
                                className="avatar avatar-sm rounded-circle mr-2"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                id="tooltip343637047"
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/james.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip343637047"
                              >
                                Ryan Tompson
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle mr-2"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                id="tooltip346352270"
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/ryan.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip346352270"
                              >
                                Romina Hadid
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle mr-2"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                id="tooltip532183863"
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/lora.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip532183863"
                              >
                                Alexander Smith
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                id="tooltip551759668"
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/mike.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip551759668"
                              >
                                Jessica Doe
                              </UncontrolledTooltip>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })
                ) : (
                  <p>Loading...</p>
                )}
                <Col md="4">
                  <Card>
                    <CardHeader className="mt-2">
                      <div className="float-left">
                        <i className="tim-icons icon-tag d-inline" />
                        <p className="d-inline ml-1">E-28</p>
                      </div>
                      <div className="float-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            aria-expanded={false}
                            caret
                            className="btn-icon"
                            color="link"
                            data-toggle="dropdown"
                            type="button"
                          >
                            <i className="tim-icons icon-settings-gear-63" />
                          </DropdownToggle>
                          <DropdownMenu right x-placement="bottom-end">
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Something else
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </CardHeader>
                    <CardBody className="text-center p-4">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img-fluid rounded-circle shadow"
                          src={require("assets/img/unass.jpg")}
                          style={{ width: "100px" }}
                        />
                      </a>
                      <CardTitle className="mb-0" tag="h4">
                        Unassigned
                      </CardTitle>
                      <p className="card-description">
                        Here you can add your description and bellow add your
                        members.
                      </p>
                      <CardTitle className="mt-4" tag="h5">
                        Members
                      </CardTitle>
                      <div className="avatar-group">
                        <a
                          className="avatar avatar-sm rounded-circle"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          id="tooltip406568354"
                        >
                          <i className="tim-icons icon-lock-circle" />
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target="tooltip406568354"
                        >
                          Add memberss
                        </UncontrolledTooltip>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <footer className="mt-5">
                <Pagination
                  className="pagination float-left"
                  listClassName="float-left"
                >
                  <PaginationItem>
                    <PaginationLink
                      aria-label="Previous"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span aria-hidden={true}>
                        <i
                          aria-hidden={true}
                          className="tim-icons icon-double-left"
                        />
                      </span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className="active">
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      1
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      2
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      ...
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      6
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      aria-label="Next"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span aria-hidden={true}>
                        <i
                          aria-hidden={true}
                          className="tim-icons icon-double-right"
                        />
                      </span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
                <div className="text-right">
                  <p>Showing 6 out of 36</p>
                  <div />
                </div>
              </footer>
            </Container>
          </div>
        </div>
        {/* ********* END PROJECTS 1 ********* */}
      </div>
    </>
  );
}

export default Projects;
