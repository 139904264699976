/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  // Button,
  UncontrolledCollapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

class ColorNavbar extends React.Component {
  state = {
    navbarColor: "navbar-transparent",
  };
  componentDidMount() {
    window.addEventListener("scroll", this.changeNavbarColor);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeNavbarColor);
  }
  changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      this.setState({
        navbarColor: "bg-primary",
      });
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      this.setState({
        navbarColor: "navbar-transparent",
      });
    }
  };
  render() {
    return (
      <>
        <Navbar className={"fixed-top " + this.state.navbarColor} expand="lg">
          <Container>
            <div className="navbar-translate">
              <NavbarBrand to="/" tag={Link} id="tooltip6619950104">
                <span>Singularity •</span> Augmenting with AI & Robotics
              </NavbarBrand>
              <UncontrolledTooltip delay={0} target="tooltip6619950104">
                Developed by Aaron Su
              </UncontrolledTooltip>
              <button className="navbar-toggler" id="navigation">
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <UncontrolledCollapse navbar toggler="#navigation">
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Singularity• <span>AI & Robotics</span>
                    </a>
                  </Col>
                  <Col className="collapse-close text-right" xs="6">
                    <button className="navbar-toggler" id="navigation">
                      <i className="tim-icons icon-simple-remove" />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink to="/" tag={Link}>
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/blogs" tag={Link}>
                    Blogs
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown nav>
                  <DropdownToggle caret color="default" nav>
                    <i
                      aria-hidden={true}
                      className="tim-icons icon-book-bookmark"
                    />
                    <p>Projects</p>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem to="/projects" tag={Link}>
                      <i className="tim-icons icon-bulb-63" />
                      All Projects
                    </DropdownItem>

                    <UncontrolledDropdown>
                      <DropdownToggle
                        caret
                        color="default"
                        className="dropdown-item"
                        tag="a"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i
                          aria-hidden={true}
                          className="tim-icons icon-book-bookmark"
                        />
                        Featured Projects
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem to="/computer-vision" tag={Link}>
                          <i className="tim-icons icon-bulb-63" />
                          Computer Vision
                        </DropdownItem>
                        {/* <DropdownItem to="/project-x" tag={Link}>
                          <i className="tim-icons icon-bulb-63" />X
                        </DropdownItem> */}
                        <DropdownItem>
                          <i className="tim-icons icon-tablet-2" />
                          More to come...
                        </DropdownItem>
                        {/* <DropdownItem to="/login-page" tag={Link}>
                          <i className="tim-icons icon-tablet-2" />
                          Login Page
                        </DropdownItem>
                        <DropdownItem to="/register-page" tag={Link}>
                          <i className="tim-icons icon-laptop" />
                          Register Page
                        </DropdownItem> */}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink to="/profile" tag={Link}>
                    About Me
                  </NavLink>
                  {/* <Button
                    className="nav-link"
                    color="primary"
                    href="/profile"
                    size="sm"
                    // target="_blank"
                  >
                    <p>About Me</p>
                  </Button> */}
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default ColorNavbar;
