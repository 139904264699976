/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// Animation using Framer Motion
import { motion, AnimatePresence } from "framer-motion";
// ReactJS plugin for a nice carousel
import Slick from "react-slick";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

//Get dummy data
import { projectList } from "variables/general";

// S3 assets URLs
const s3 = require("assets/s3-assets.json");

// custom previous button for the slick component
const PrevButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-prev slick-arrow"
      color="primary"
      aria-label="Previous"
      type="button"
      onClick={props.onClick}
    >
      <i className="tim-icons icon-minimal-left" />
    </Button>
  );
};
// custom next button for the slick component
const NextButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-next slick-arrow"
      color="primary"
      aria-label="Next"
      type="button"
    >
      <i className="tim-icons icon-minimal-right" onClick={props.onClick} />
    </Button>
  );
};

function Presentation() {
  const [isUnlocked, unlock] = useState(false);
  const [current, setCurrent] = useState(0);
  const history = useHistory();

  let slickHeaderSettings = {
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    afterChange: (current) => setCurrent(current),
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    className: "center slider slick-buttons-under",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  return (
    <>
      <div className="cd-section" id="headers">
        <div className="header header-ainz">
          <Navbar className="navbar-transparent navbar-absolute" expand="lg">
            <Container>
              <div className="navbar-translate">
                <button className="navbar-toggler" id="example-header-3">
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
                <NavbarBrand href="/" onClick={() => history.push("/")}>
                  Singularity
                </NavbarBrand>
              </div>
              <UncontrolledCollapse navbar toggler="#example-header-3">
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Singularity
                      </a>
                    </Col>
                    <Col className="collapse-close text-right" xs="6">
                      <button className="navbar-toggler" id="example-header-3">
                        <i className="tim-icons icon-simple-remove" />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    <NavLink
                      href="#pablo"
                      onClick={() => history.push("/projects")}
                    >
                      Projects
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#pablo"
                      onClick={() => history.push("/blogs")}
                    >
                      Blogs
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#pablo"
                      onClick={() => history.push("/profile")}
                    >
                      About Me
                    </NavLink>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
          <div className="page-header header-filter">
            {/* ********* Cover ********* */}
            <video
              autoPlay="autoplay"
              loop="loop"
              muted="muted"
              playsInline="playsinline"
            >
              <source
                // src={require("assets/video/space-bg-1.mp4")}
                src={s3.video.space_bg}
                type="video/mp4"
              />
            </video>
            <AnimatePresence>
              {!isUnlocked && (
                <motion.div
                  className="cover"
                  initial={{ opacity: 0.3 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 3 }}
                >
                  <div className="overlay-cover" />
                  <div
                    className="logo-image-alpha"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/logo/blackhole_logo_alpha_2.png") +
                        ")",
                    }}
                  />

                  <div className="content-center">
                    {/* <img
                      className="logo-image-alpha"
                      src={require("assets/img/logo/blackhole_logo_alpha_2.png")}
                      alt="logo-cover"
                    /> */}
                    <Row className="image-input-field">
                      <Col className="ml-auto mr-auto text-right" lg="4">
                        <br />
                        <motion.h2
                          initial={{ opacity: 0, x: 80 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 4 }}
                        >
                          Unlock the power of
                        </motion.h2>
                      </Col>
                      <Col className="ml-auto mr-auto" lg="4">
                        <br />
                        <motion.div
                          // whileHover={{ scale: 1.2, rotate: 90 }}
                          whileTap={{
                            scale: 0.8,
                            // rotate: -90,
                          }}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                        >
                          <Button
                            className="btn-round btn-simple btn-neutral btn-icon"
                            color="default"
                            size="lg"
                            onClick={() => unlock(true)}
                          >
                            <i className="tim-icons icon-lock-circle" />
                          </Button>
                        </motion.div>
                      </Col>
                      <Col className="ml-auto mr-auto text-left" lg="4">
                        <motion.h1
                          className="title"
                          initial={{ opacity: 0, x: -80 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 5 }}
                        >
                          AI & Robotics
                        </motion.h1>
                        <br />
                      </Col>
                    </Row>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
            {/* ********* End Cover ********* */}
            {isUnlocked && (
              <div>
                <div className="overlay" />
                <video
                  autoPlay="autoplay"
                  loop="loop"
                  muted="muted"
                  playsInline="playsinline"
                >
                  <source
                    // src={require("assets/video/tech-bg-6.mp4")}
                    src={s3.video.tech_bg}
                    type="video/mp4"
                  />
                </video>
                <div className="content-center">
                  <Row>
                    <Col
                      className="ml-auto mr-auto positioned"
                      lg="5"
                      md="8"
                      xs="12"
                    >
                      <h1 className="title">{projectList[current].title}</h1>
                      <p className="description">
                        {projectList[current].description}
                      </p>
                      <Button
                        color="primary"
                        className="animation-on-hover"
                        onClick={() =>
                          history.push(projectList[current].siteURL)
                        }
                        size="lg"
                      >
                        Learn more
                      </Button>
                    </Col>
                    <Col md="12">
                      <Slick {...slickHeaderSettings}>
                        {projectList.map((project, index) => {
                          return project.featured ? (
                            <div key={index}>
                              <img
                                src={require(`assets/img/${project.coverURL}`)}
                                alt="..."
                                height="500"
                                width="450"
                              />
                            </div>
                          ) : null;
                        })}
                      </Slick>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Presentation;
