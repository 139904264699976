/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Carousel,
  CarouselItem,
  Col,
  Container,
  NavItem,
  NavLink,
  Nav,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  UncontrolledAlert,
} from "reactstrap";
import Lottie from "react-lottie";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import DropzoneSingleUpload from "components/CustomUpload/DropzoneSingleUpload.js";
import ResultsTable from "components/Display/ResultsTable.js";
import * as analytics from "assets/lottie/analytics.json";
import * as spinner from "assets/lottie/spinner.json";

// import fetch functions
import { serverIsReady } from "actions/fetchActions";

const analyticsOption = {
  loop: true,
  autoplay: true,
  animationData: analytics.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const spinnerOption = {
  loop: true,
  autoplay: true,
  animationData: spinner.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const items = [
  {
    content: (
      <Container>
        <Row>
          <Col md="4">
            <h1 className="title">Hiranya Jayakody</h1>
          </Col>
          <Col md="4">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/hiranya-dp.jpg")}
            />
          </Col>
          <Col md="4">
            <div className="wrapper">
              <div className="category">
                <strong>Role:</strong> Researcher <br />
                <strong>Specialization:</strong> <br />
                Image Processing <br />
                Machine Learning
              </div>
              <div className="description">
                Hiranya Jayakody obtained his PhD from UNSW Australia in 2016.
                His PhD focused on modeling and robust fast control of different
                types of Unmanned Aerial Vehicles. Hiranya’s role at SRV group
                primarily involves developing image processing and machine
                learning solutions for stomate detection.
              </div>
              <div className="footer">
                <Button
                  className="btn-icon btn-round"
                  color="linkedin"
                  href="https://www.linkedin.com/in/hiranya-jayakody/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "0",
  },
  {
    content: (
      <Container>
        <Row>
          <Col md="4">
            <h1 className="title">Aaron Su</h1>
          </Col>
          <Col md="4">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/aaron-dp.jpg")}
            />
          </Col>
          <Col md="4">
            <div className="wrapper">
              <div className="category">
                <strong>Role:</strong> Developer & Architect <br />
                <strong>Specialization:</strong> <br />
                Solution Architecture <br />
                Full-stack Development <br />
                Robotics
              </div>
              <div className="description">
                Aaron Su graduated with Honours Class 1 in Mechatronics and
                Distinction in Financial Economics from UNSW. Aaron completed
                his scholarship project with SRV team and his role primarily
                involves architecting the cloud solution as well as full-stack
                development for deploying ML algorithms online.
              </div>
              <div className="footer">
                <Button
                  className="btn-icon btn-round"
                  color="linkedin"
                  href="https://www.linkedin.com/in/aaronsu11"
                  target="_blank"
                >
                  <i className="fab fa-linkedin" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="github"
                  href="https://github.com/aaronsu11"
                  id="tooltip304767046"
                  target="_blank"
                >
                  <i className="fab fa-github" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "1",
  },
];

function ComputerVision() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [focus, setFocus] = useState(false);
  const [hTabs, setHTables] = useState(1);
  const [status, setStatus] = useState("idle");
  const [results, setResults] = useState({});
  const [timer, setTimer] = useState();
  const [ready, setReady] = useState(false);

  const wrapper = useRef(null);

  const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const toggleTabs = (e, index) => {
    e.preventDefault();
    setHTables(index);
  };

  // Initialize a timer
  useEffect(() => {
    async function checkServer() {
      // This is the right way to define async in useEffect
      let status = await serverIsReady();
      setReady(status);
    }
    checkServer();
    const interval = setInterval(() => {
      // console.log("Checking server status");
      checkServer();
    }, 20000);
    setTimer(interval);
    return () => clearInterval(interval);
  }, []);

  // Clear timer when server ready
  useEffect(() => {
    // console.log(ready);
    if (ready === true) {
      console.log("Server Ready");
      clearInterval(timer);
    } else if (ready === null) {
      console.log("Error occurred, please retry later.");
      clearInterval(timer);
    }
  }, [ready, timer]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // window.scrollTo(0, wrapper.current.offsetTop);
    document.body.classList.add("project-cv");
    console.log(wrapper);
    return () => {
      document.body.classList.remove("project-cv");
    };
  }, [wrapper]);

  return (
    <>
      <ColorNavbar />
      <div className="wrapper ainz-project" ref={wrapper}>
        <div className="header header-5">
          <div className="page-header">
            <Container>
              <Row className="align-items-center mt-md-5">
                <Col className="ml-auto mr-auto" lg="7" md="6">
                  <Row className="logo">
                    {/* <div className="logo-image">
                      <img
                        alt="..."
                        src={require("assets/img/logo/blackhole_logo_w.png")}
                      />
                    </div> */}
                    <h1 className="title">Computer Vision</h1>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Nav className="nav-tabs-primary" tabs role="tablist">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: hTabs === 1,
                            })}
                            onClick={(e) => toggleTabs(e, 1)}
                            href="#pablo"
                          >
                            <i className="tim-icons icon-notes" />
                            Stomata Detection
                          </NavLink>
                        </NavItem>
                        {/* <NavItem>
                          <NavLink
                            className={classnames({
                              active: hTabs === 2,
                            })}
                            onClick={(e) => toggleTabs(e, 2)}
                            href="#pablo"
                          >
                            <i className="tim-icons icon-badge" />
                            Face Identification
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: hTabs === 3,
                            })}
                            onClick={(e) => toggleTabs(e, 3)}
                            href="#pablo"
                          >
                            <i className="tim-icons icon-app" />
                            Object Recognition
                          </NavLink>
                        </NavItem> */}
                      </Nav>
                      <TabContent
                        className="tab-space"
                        activeTab={"hTabs" + hTabs}
                      >
                        <TabPane tabId="hTabs1">
                          <h3 className="text-left">
                            A robust stomata detection and instance segmentation
                            method for microscope images of plant leaves,
                            developed by Hiranya Jayakody.
                          </h3>
                          Smart Robotics Viticulture Group, UNSW, Sydney, April
                          2020.
                          <br />
                          Github:
                          https://github.com/Smart-Robotic-Viticulture/MaskStomata.git
                        </TabPane>
                        {/* <TabPane tabId="hTabs2">
                          Efficiently unleash cross-media information without
                          cross-media value. Quickly maximize timely
                          deliverables for real-time schemas. <br />
                          <br />
                          Dramatically maintain clicks-and-mortar solutions
                          without functional solutions.
                        </TabPane>
                        <TabPane tabId="hTabs3">
                          Completely synergize resource taxing relationships via
                          premier niche markets. Professionally cultivate
                          one-to-one customer service with robust ideas. <br />
                          <br />
                          Dynamically innovate resource-leveling customer
                          service for state of the art customer service.
                        </TabPane> */}
                      </TabContent>
                    </Col>
                  </Row>
                  {/* TODO */}
                  {/* {status === "idle" && (
                    <Row className="image-input-field">
                      <Col lg="7">
                        <InputGroup
                          className={classnames({
                            "input-group-focus": focus,
                          })}
                        >
                          <Input
                            placeholder="Enter the public URL of the image"
                            type="text"
                            onFocus={(e) => setFocus(true)}
                            onBlur={(e) => setFocus(false)}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <i className="tim-icons icon-link-72" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                      <Col lg="1">
                        <p className="text-sm">OR</p>
                      </Col>
                      <Col lg="4">
                        <Button
                          className="btn-simple upload-btn"
                          color={focus ? "default" : "primary"}
                          onClick={async () => {
                            let response = await serverIsReady();
                            console.log(response);
                          }}
                        >
                          Upload{" "}
                          <i className="tim-icons icon-cloud-upload-94" />
                        </Button>
                      </Col>
                    </Row>
                  )} */}
                  {status === "uploaded" && (
                    <div className="mt-n5">
                      <Lottie
                        options={analyticsOption}
                        height={400}
                        width={583}
                      />
                    </div>
                  )}
                  {status === "processed" && <ResultsTable results={results} />}
                </Col>
                <Col className="ml-auto text-center" lg="5" md="6">
                  <img
                    alt="..."
                    className="shape"
                    src={require("assets/img/patrat.png")}
                  />
                  <img
                    alt="..."
                    className="shape2"
                    src={require("assets/img/cercuri.png")}
                  />
                  <img
                    alt="..."
                    className="shape3"
                    src={require("assets/img/waves.png")}
                  />
                  <Card className="card-blog card-plain">
                    <div className="dzu-dropzone-wrapper">
                      <DropzoneSingleUpload
                        serverReady={ready}
                        setStatus={setStatus}
                        setResults={setResults}
                      />
                    </div>
                    <CardBody>
                      {ready ? (
                        <div>
                          <i className="tim-icons icon-minimal-up" />
                          <br />
                          <Badge
                            color="primary"
                            className="mt-2"
                            id="badge-ready"
                          >
                            Ready
                          </Badge>
                          <UncontrolledTooltip
                            delay={0}
                            placement="right"
                            target="badge-ready"
                          >
                            Server is now ready
                          </UncontrolledTooltip>
                        </div>
                      ) : (
                        <div>
                          <Lottie
                            options={spinnerOption}
                            height={30}
                            width={30}
                          />
                          <Badge color="warning" id="badge-pending">
                            Pending
                          </Badge>
                          <UncontrolledTooltip
                            delay={0}
                            placement="right"
                            target="badge-pending"
                          >
                            Server is still preparing
                          </UncontrolledTooltip>
                        </div>
                      )}

                      <CardTitle tag="h5">
                        <a
                          href="http://www.robotics.unsw.edu.au/srv/stomata.html"
                          rel="noopener noreferrer"
                          target="_blank"
                          // onClick={(e) => e.preventDefault()}
                        >
                          Automated Stomata Analysis
                        </a>
                      </CardTitle>
                      <p className="card-description">
                        Stomatal density and behavior are good indicators of the
                        overall health of a plant as well as its surrounding
                        environment.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {/* <S3Upload /> */}
        {/* <DisplayImage /> */}

        <div className="section about-description">
          <img
            alt="..."
            className="path path4"
            src={require("assets/img/path4.png")}
          />
          <Container>
            <Row className="mb-5">
              <Col className="ml-auto mr-auto text-center" md="8">
                <Badge color="info">Team</Badge>
                <h2 className="title">Smart Robotic Viticulture</h2>
                <h4 className="description">
                  The Smart Robotic Viticulture team, led by Dr Mark Whitty,
                  invents and develops algorithms for image processing which
                  make your agtech personnel and machines smarter and more
                  efficient.
                </h4>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <img
                  alt="..."
                  className="d-block"
                  src={require("assets/img/srv-header.jpg")}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="features-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <Badge color="success">Highlights</Badge>
                <h1 className="title">Our Competitive Advantage</h1>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-primary">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={require("assets/img/feature-blob/primary.png")}
                    />
                    <i className="tim-icons icon-book-bookmark" />
                  </div>
                  <h4 className="info-title">Expertise & Experience</h4>
                  <p className="description">
                    Extensive experience in agricultural and viticultural image
                    sensing and field data collection.
                  </p>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-success">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={require("assets/img/feature-blob/success.png")}
                    />
                    <i className="tim-icons icon-atom" />
                  </div>
                  <h4 className="info-title">Data-driven</h4>
                  <p className="description">
                    Robust algorithms backed by ground-truthed data
                  </p>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-warning">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={require("assets/img/feature-blob/warning.png")}
                    />
                    <i className="tim-icons icon-user-run" />
                  </div>
                  <h4 className="info-title">Agile Team</h4>
                  <p className="description">
                    Rapidly scalable team, able to adapt to new domains ranging
                    from vines to beaches to warehouse racking to quantum
                    computing!
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section">
          <div className="team-1">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <Badge color="neutral">Members</Badge>
                  <h2 className="title">Project Contributors</h2>
                  <h4 className="description">
                    The above algorithms we developed for stomate detection and
                    analysis are being used by researchers in paleoecology in
                    the Netherlands.
                  </h4>
                </Col>
              </Row>
              <Row>
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  className="carousel-team"
                >
                  {items.map((item, key) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={key}
                      >
                        {item.content}
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="tim-icons icon-minimal-left" />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="tim-icons icon-minimal-right" />
                    <span className="sr-only">Next</span>
                  </a>
                </Carousel>
              </Row>
            </Container>
          </div>
        </div>
        <DemoFooter />
      </div>
    </>
  );
}

export default ComputerVision;
